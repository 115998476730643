// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "bootstrap";
import Rails from "@rails/ujs";
// import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "trix";
import "@rails/actiontext";
import cronstrue from 'cronstrue/i18n';
import Chart from 'chart.js/auto';

import {
  showMessage,
  showError,
  showSuccess,
  showInfo,
  call_api
} from "./custom/globalFunctions"

Rails.start();

ActiveStorage.start();
require("trix");
require("@rails/actiontext");

window.showInfo = showInfo;
window.showError = showError;
window.showSuccess = showSuccess;
window.showMessage = showMessage;
window.call_api = call_api;
window.cronstrue = cronstrue;
window.Chart = Chart;
