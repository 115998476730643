const showError = (message) => {
  showMessage(message, {
    background: '#dc3545',
    color: 'white',
  });
}

const showSuccess = (message) => {
  showMessage(message, {
    background: "#28a745",
    color: 'white'
  });
}

const showInfo = (message) => {
  showMessage(message, {
    background: "#17a2b8",
    color: 'white'
  });
}

const showMessage = (message, style) => {
  Toastify({
    text: message,
    duration: 3000,
    gravity: "top",
    position: 'right',
    style,
    stopOnFocus: true,
  }).showToast();
}

const call_api = (url, method = 'get', data, successMessage = "Operación realizada correctamente", errorMessage = "Se produjó un error") => {
  return new Promise((resolve, reject) => {

    if (successMessage || errorMessage) {
      showInfo("Enviando petición...");
    }

    const opc = {
      method: method.toLowerCase(),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      },
    }


    if (data) {

      if (method && method.toLowerCase() !== "get") {
        opc.body = JSON.stringify(data);

      } else {
        url += "?" + new URLSearchParams(data).toString();
      }
    }

    fetch(url, opc)
      .then(response => {
        if (response.ok) {
          if (successMessage) {
            showSuccess(successMessage);
          }
          resolve(response.json());
        } else {
          response.json().then(data => {
            if (errorMessage) {
              const messageToDisplay = data.error || errorMessage;
              showError(messageToDisplay);
            }
            reject(data);
          });
        }
      })
      .catch(error => {
        if (errorMessage) {
          showError(errorMessage);
        }
        reject(error);
      });
  });
}


export {
  showError,
  showSuccess,
  showInfo,
  showMessage,
  call_api
}